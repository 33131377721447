<template>
  <div
    v-if="
      single_pretarget.webTargeting ||
        single_pretarget.appTargeting ||
        single_pretarget.publisherTargeting
    "
  >
    <v-row v-if="single_pretarget.webTargeting">
      <v-col cols="3">
        <h6>Web Targeting</h6>
      </v-col>
      <v-col v-if="single_pretarget.webTargeting.targetingMode">
        <h6>Targeting Mode:</h6>
        <span class="text-caption">
          {{
            capitalizeFirstLetter(
              single_pretarget.webTargeting.targetingMode.toLowerCase()
            )
          }}
        </span>
      </v-col>
      <v-col v-if="single_pretarget.webTargeting.values">
        <h6>Targeted Sites</h6>
        <h6
          class="text-caption"
          v-for="(item, i) in single_pretarget.webTargeting.values"
          :key="i"
        >
          {{ item }}
        </h6>
      </v-col>
    </v-row>

    <v-row v-if="single_pretarget.appTargeting && available_apps.length ">
      <v-col cols="3">
        <h6>App Targeting</h6>
      </v-col>
      <v-col v-if="single_pretarget.appTargeting.targetingMode"
        ><h6>Targeting Mode</h6>
        <span class="text-caption">
          {{
            capitalizeFirstLetter(
              single_pretarget.appTargeting.targetingMode.toLowerCase()
            )
          }}
        </span>
      </v-col>
      <v-col
        v-if="single_pretarget.appTargeting.values && available_apps.length"
        ><h6>Targeted Apps</h6>
        <h6 class="text-caption" v-for="(item, i) in prefill_apps" :key="i">
          {{ item.entityName }}
        </h6>
      </v-col>
    </v-row>

    <v-row v-if="single_pretarget.publisherTargeting && available_publishers.length">
      <v-col cols="3">
        <h6>Pulisher Targeting</h6>
      </v-col>
      <v-col v-if="single_pretarget.publisherTargeting.targetingMode"
        ><h6>Targeting Mode</h6>
        {{
          capitalizeFirstLetter(
            single_pretarget.publisherTargeting.targetingMode.toLowerCase()
          )
        }}
      </v-col>
      <v-col
        v-if="
          single_pretarget.publisherTargeting.values &&
            available_publishers.length
        "
        ><h6>Targeted Publishers</h6>
        <h6
          class="text-caption"
          v-for="(item, i) in prefill_publishers"
          :key="i"
        >
          {{ item.displayName }}
        </h6>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  data: () => ({
    available_apps: [],
    prefill_apps: [],
    prefill_publishers: [],
    available_publishers: [],
  }),

  props: {
    single_pretarget: {
      type: Object,
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      const capitalized = string.charAt(0).toUpperCase() + string.slice(1);
      return capitalized;
    },
  },
};
</script>

<style></style>
