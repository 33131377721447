var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.single_pretarget.webTargeting ||
      _vm.single_pretarget.appTargeting ||
      _vm.single_pretarget.publisherTargeting
  )?_c('div',[(_vm.single_pretarget.webTargeting)?_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('h6',[_vm._v("Web Targeting")])]),(_vm.single_pretarget.webTargeting.targetingMode)?_c('v-col',[_c('h6',[_vm._v("Targeting Mode:")]),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter( _vm.single_pretarget.webTargeting.targetingMode.toLowerCase() ))+" ")])]):_vm._e(),(_vm.single_pretarget.webTargeting.values)?_c('v-col',[_c('h6',[_vm._v("Targeted Sites")]),_vm._l((_vm.single_pretarget.webTargeting.values),function(item,i){return _c('h6',{key:i,staticClass:"text-caption"},[_vm._v(" "+_vm._s(item)+" ")])})],2):_vm._e()],1):_vm._e(),(_vm.single_pretarget.appTargeting && _vm.available_apps.length )?_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('h6',[_vm._v("App Targeting")])]),(_vm.single_pretarget.appTargeting.targetingMode)?_c('v-col',[_c('h6',[_vm._v("Targeting Mode")]),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter( _vm.single_pretarget.appTargeting.targetingMode.toLowerCase() ))+" ")])]):_vm._e(),(_vm.single_pretarget.appTargeting.values && _vm.available_apps.length)?_c('v-col',[_c('h6',[_vm._v("Targeted Apps")]),_vm._l((_vm.prefill_apps),function(item,i){return _c('h6',{key:i,staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.entityName)+" ")])})],2):_vm._e()],1):_vm._e(),(_vm.single_pretarget.publisherTargeting && _vm.available_publishers.length)?_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('h6',[_vm._v("Pulisher Targeting")])]),(_vm.single_pretarget.publisherTargeting.targetingMode)?_c('v-col',[_c('h6',[_vm._v("Targeting Mode")]),_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter( _vm.single_pretarget.publisherTargeting.targetingMode.toLowerCase() ))+" ")]):_vm._e(),(
        _vm.single_pretarget.publisherTargeting.values &&
          _vm.available_publishers.length
      )?_c('v-col',[_c('h6',[_vm._v("Targeted Publishers")]),_vm._l((_vm.prefill_publishers),function(item,i){return _c('h6',{key:i,staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.displayName)+" ")])})],2):_vm._e()],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }