<template>
  <div>
    <v-row v-if="single_pretarget.includedFormats">
      <v-col cols="3">
        <h6>Creative Formats:</h6>
      </v-col>
      <v-col>
        <h6
          class="text-caption"
          v-for="(item, i) in single_pretarget.includedFormats"
          :key="i"
        >
          {{ item }}
        </h6>
      </v-col>
    </v-row>
    <v-row
      v-if="
        single_pretarget.includedCreativeDimensions &&
        single_pretarget.includedCreativeDimensions.length
      "
    >
      <v-col cols="3">
        <h6>Creative Dimensions</h6>
      </v-col>
      <v-col>
        <h6 class="mb-1">Height</h6>
        <h6
          class="text-caption"
          v-for="(item, i) in single_pretarget.includedCreativeDimensions"
          :key="i"
        >
          {{ item.height ? item.height : "Not Set" }}
        </h6>
      </v-col>
      <v-col>
        <h6 class="mb-1">Width</h6>
        <h6
          class="text-caption"
          v-for="(item, i) in single_pretarget.includedCreativeDimensions"
          :key="i"
        >
          {{ item.width ? item.width : "Not Set" }}
        </h6>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    single_pretarget: {
      type: Object,
    },
  },
};
</script>

<style></style>
