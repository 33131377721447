<template>
  <v-row>
    <v-col cols="12">
      <general-card class="pa-5 d-flex justify-space-between align-center">
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <div>
          <v-btn
            small
            depressed
            outlined
            color="#8cc73e"
            class="custom-transform-class mr-2"
            @click="navigatetoUpdate"
            >Edit Pretarget</v-btn
          >
          <v-btn
            v-if="single_pretarget"
            small
            depressed
            outlined
            color="#8cc73e"
            class="custom-transform-class mr-2"
            @click="editInventory"
            >Edit Apps, Sites and Publishers</v-btn
          >
          <v-btn
            v-if="state === 'ACTIVE'"
            small
            depressed
            outlined
            color="#8cc73e"
            class="custom-transform-class mr-2"
            @click="suspendPretarget"
            >Suspend</v-btn
          >
          <v-btn
            v-if="state === 'SUSPENDED'"
            small
            depressed
            outlined
            color="#8cc73e"
            class="custom-transform-class"
            @click="activatePretarget"
            >Activate</v-btn
          >
          <v-btn
            small
            depressed
            outlined
            color="#FF0000"
            class="custom-transform-class"
            @click="deletePretarget"
            >Delete</v-btn
          >
        </div>
      </general-card>
    </v-col>
    <v-col cols="7">
      <general-card class="pa-5" v-if="single_pretarget">
        <v-row v-if="single_pretarget.displayName">
          <v-col cols="3">
            <h6>Name:</h6>
          </v-col>
          <v-col>
            <span class="text-caption">
              {{ capitalizeFirstLetter(single_pretarget.displayName) }}
            </span>
          </v-col>
        </v-row>
        <included-creative-formats
          :single_pretarget="single_pretarget"
          v-if="single_pretarget.includedFormats"
        />
        <set-geo-targeting
          :single_pretarget="single_pretarget"
          :included_geo_locations_by_id="included_geo_locations_by_id"
          :excluded_geo_locations_by_id="excluded_geo_locations_by_id"
          v-if="single_pretarget.geoTargeting"
          @setExcludedId="getExcludedId($event)"
          @setIncludedId="getIncludedId($event)"
        />
        <included-environments
          v-if="single_pretarget.includedEnvironments"
          :single_pretarget="single_pretarget"
        />
        <user-list-targeting
          v-if="single_pretarget.userListTargeting"
          :single_pretarget="single_pretarget"
          :user_lists="user_lists"
        />
        <content-labels
          v-if="single_pretarget.excludedContentLabelIds"
          :single_pretarget="single_pretarget"
          :content_labels="content_labels"
        />
      </general-card>
    </v-col>
    <v-col>
      <general-card class="pa-5" v-if="single_pretarget">
        <inventory-targeting :single_pretarget="single_pretarget" />
        <market-targeting
          :single_pretarget="single_pretarget"
          :vertical_targeting="vertical_targeting"
          :languages="languages"
        />
        <user-targeting
          :single_pretarget="single_pretarget"
          :mobile_os="mobile_os"
        />
      </general-card>
    </v-col>
  </v-row>
</template>
<script>
import GeneralCard from "../../../components/Layouts/Cards/GeneralCard.vue";
import IncludedCreativeFormats from "../../../components/Programmatic/PretargetView/IncludedCreativeFormats.vue";
import SetGeoTargeting from "../../../components/Programmatic/PretargetView/SetGeoTargeting.vue";
import IncludedEnvironments from "../../../components/Programmatic/PretargetView/IncludedEnvironments.vue";
import UserListTargeting from "../../../components/Programmatic/PretargetView/UserListTargeting.vue";
import ContentLabels from "../../../components/Programmatic/PretargetView/ContentLabels.vue";
import UserTargeting from "../../../components/Programmatic/PretargetView/UserTargeting.vue";
import InventoryTargeting from "../../../components/Programmatic/PretargetView/InventoryTargeting.vue";
import MarketTargeting from "../../../components/Programmatic/PretargetView/MarketTargeting.vue";

export default {
  components: {
    GeneralCard,
    IncludedCreativeFormats,
    SetGeoTargeting,
    IncludedEnvironments,
    UserListTargeting,
    ContentLabels,
    UserTargeting,
    InventoryTargeting,
    MarketTargeting,
  },
  data: () => ({
    items: [
      {
        text: "Pretargets",
        disabled: false,
        href: "#/tradedesk/targeting",
      },
      {
        text: "Single Pretarget",
        disabled: true,
        href: "#",
      },
    ],
    state: null,
    geo_objects: [],
    geo_object: { id: [] },
  }),

  computed: {
    single_pretarget() {
      return this.$store.state.dsp.single_pretarget;
    },
    content_labels() {
      return this.$store.state.dsp.content_labels;
    },
    user_lists() {
      return this.$store.state.dsp.user_lists;
    },
    mobile_os() {
      return this.$store.state.dsp.mobile_os;
    },
    vertical_targeting() {
      return this.$store.state.dsp.vertical_targeting;
    },
    languages() {
      return this.$store.state.dsp.languages;
    },
    included_geo_locations_by_id() {
      return this.$store.state.dsp.included_geo_locations_by_id.flat();
    },
    excluded_geo_locations_by_id() {
      return this.$store.state.dsp.excluded_geo_locations_by_id.flat();
    },
  },

  async mounted() {
    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch(
        "dsp/getSinglePretarget",
        this.$route.params.id
      );
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error;
      this.$store.commit("makingApiRequest", false);
    }

    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("dsp/contentCategoryLabels");
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error;
      this.$store.commit("makingApiRequest", false);
    }

    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("dsp/getGoogleUserLists");
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error;
      this.$store.commit("makingApiRequest", false);
    }

    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("dsp/mobileOperatingSystemIds");
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error;
      this.$store.commit("makingApiRequest", false);
    }

    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("dsp/getAllLanguages");
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error;
      this.$store.commit("makingApiRequest", false);
    }

    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("dsp/getVerticalTargeting");
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error;
      this.$store.commit("makingApiRequest", false);
    }

    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("dsp/getVerticalTargeting");
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error;
      this.$store.commit("makingApiRequest", false);
    }

    // try {
    //   this.$store.commit("makingApiRequest", true);
    //   await this.$store.dispatch("dsp/locationbyId", this.geo_object);
    //   this.$store.commit("makingApiRequest", false);
    // } catch (error) {
    //   this.error = error;
    // }
  },
  methods: {
    async getExcludedId(ids) {
      this.geo_object.id = ids;
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/excludedLocationbyId", this.geo_object);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
      }
    },

    async getIncludedId(ids) {
      this.geo_object.id = ids;
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/includedLocationbyId", this.geo_object);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
      }
    },

    navigatetoUpdate() {
      this.$router.push({
        name: "edit-pretarget",
        params: this.$route.params.id,
      });
    },
    capitalizeFirstLetter(string) {
      const capitalized = string.charAt(0).toUpperCase() + string.slice(1);
      return capitalized;
    },
    breakArray(array) {
      for (var i = 0; i < array.length; i++) {
        return array[i].trim();
      }
    },
    async suspendPretarget() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch(
          "dsp/suspendPretarget",
          this.$route.params.id
        );
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Suspended successfully!",
          group: "success",
        });
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    async activatePretarget() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch(
          "dsp/activatePretarget",
          this.$route.params.id
        );
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Activated successfully!",
          group: "success",
        });
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    async deletePretarget() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch(
          "dsp/deletePretarget",
          this.$route.params.id
        );
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Deleted successfully!",
          group: "success",
        });
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    editInventory() {
      this.$router.push({
        name: "edit-inventory",
        params: this.$route.params.id,
      });
    },
  },
  watch: {
    single_pretarget() {
      if (this.single_pretarget) {
        this.state = this.single_pretarget.state.trim();
      }
    },
  },
};
</script>

<style></style>
