<template>
  <div>
    <v-row v-if="single_pretarget && single_pretarget.interstitialTargeting">
      <v-col>
        <h6>InterstitialTargeting:</h6>
      </v-col>
      <v-col>
        <p class="text-caption">
          {{
            capitalizeFirstLetter(
              single_pretarget.interstitialTargeting
                .replaceAll("_", " ")
                .toLowerCase()
            )
          }}
        </p>
      </v-col>
    </v-row>
    <v-row
      v-if="single_pretarget && single_pretarget.allowedUserTargetingModes"
    >
      <v-col>
        <h6>User Targeting Modes:</h6>
      </v-col>
      <v-col>
        <h6
          class="text-caption"
          v-for="(item, i) in single_pretarget.allowedUserTargetingModes"
          :key="i"
        >
          {{ capitalizeFirstLetter(item.replaceAll("_", " ").toLowerCase()) }}
        </h6>
      </v-col>
    </v-row>
    <v-row v-if="single_pretarget && single_pretarget.includedUserIdTypes">
      <v-col>
        <h6>User Identifiers</h6>
      </v-col>
      <v-col>
        <h6
          class="text-caption"
          v-for="(item, i) in single_pretarget.includedUserIdTypes"
          :key="i"
        >
          {{ capitalizeFirstLetter(item.replaceAll("_", " ").toLowerCase()) }}
        </h6>
      </v-col>
    </v-row>
    <v-row v-if="single_pretarget && included_mobileOs.length">
      <v-col>
        <h6>Included Mobile OS</h6>
      </v-col>
      <v-col v-if="included_mobileOs.length">
        <h6
          class="text-caption"
          v-for="(item, i) in included_mobileOs"
          :key="i"
        >
          {{ item ? item.platform_name : null }}
        </h6>
      </v-col>
    </v-row>
    <v-row v-if="single_pretarget && single_pretarget.minimumViewabilityDecile">
      <v-col><h6>Minimum Predicted viewability of adslots</h6> </v-col>
      <v-col>
        <p class="text-caption">
          {{ single_pretarget.minimumViewabilityDecile }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    included_mobileOs: [],
  }),
  props: {
    single_pretarget: {
      type: Object,
    },
    mobile_os: {
      type: Array,
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      const capitalized = string.charAt(0).toUpperCase() + string.slice(1);
      return capitalized;
    },
  },
  watch: {
    mobile_os() {
      if (
        this.single_pretarget &&
        this.single_pretarget.includedMobileOperatingSystemIds &&
        this.single_pretarget.includedMobileOperatingSystemIds.length
      ) {
        this.single_pretarget.includedMobileOperatingSystemIds.map(
          (id) =>
            (this.included_mobileOs = this.mobile_os.filter(
              (os) => os.version_id === id
            ))
        );
      }
    },
  },
};
</script>

<style></style>
