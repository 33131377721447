<template>
  <v-row>
    <v-col cols="3">
      <h6>UserList Targeting:</h6>
    </v-col>
    <v-col>
      <h6>Included UserLists</h6>
      <h6 class="text-caption" v-for="(item, i) in included_userlists" :key="i">
        {{ item.displayName }}
      </h6>
    </v-col>
    <v-col>
      <h6>Excluded UserLists</h6>
      <h6 class="text-caption" v-for="(item, i) in excluded_userlists" :key="i">
        {{ item.displayName }}
      </h6>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    included_userlists: [],
    excluded_userlists: [],
    all: null,
  }),
  props: {
    single_pretarget: {
      type: Object,
    },
    user_lists: {
      type: Array,
    },
  },
  watch: {
    user_lists() {
      if (
        this.user_lists &&
        this.single_pretarget.userListTargeting.includedIds
      ) {
        let ids = this.single_pretarget.userListTargeting.includedIds;
        ids.forEach((element) => {
          let all = this.user_lists.find(
            (list) => list.name.includes(element) === true
          );
          this.included_userlists.push(all);
        });
      }
      if (
        this.user_lists &&
        this.single_pretarget.userListTargeting.excludedIds
      ) {
        let excluded = this.single_pretarget.userListTargeting.excludedIds;
        excluded.forEach((element) => {
          let all = this.user_lists.find(
            (list) => list.name.includes(element) === true
          );
          this.excluded_userlists.push(all);
        });
      }
    },
  },
};
</script>

<style></style>
