<template>
  <v-row v-if="labels.length">
    <v-col cols="3">
      <h6>Content excluded in this pretarget:</h6>
    </v-col>
    <v-col>
      <h6 class="text-caption" v-for="(item, i) in labels" :key="i">
        {{ item.label }}
      </h6>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    labels: [],
  }),
  props: {
    single_pretarget: {
      type: Object,
    },
    content_labels: {
      type: Array,
    },
  },
  watch: {
    content_labels() {
      if (this.single_pretarget) {
        {
          let codes = this.single_pretarget.excludedContentLabelIds.map(Number);
          codes.forEach((element) => {
            let all = this.content_labels.find(
              (label) => label.code === element
            );
            this.labels.push(all);
          });
        }
      }
    },
  },
};
</script>

<style></style>
