<template>
  <v-row>
    <v-col cols="3">
      <h6>Included Environments</h6>
    </v-col>
    <v-col>
      <h6
        class="text-caption"
        v-for="(item, i) in single_pretarget.includedEnvironments"
        :key="i"
      >
        {{ capitalizeFirstLetter(item.toLowerCase()) }}
      </h6>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    single_pretarget: {
      type: Object,
    },
  },
  methods:{
    capitalizeFirstLetter(string) {
      const capitalized = string.charAt(0).toUpperCase() + string.slice(1);
      return capitalized;
    },
  }
};
</script>

<style></style>
