<template>
  <div>
    <v-row v-if="single_pretarget && included_languages.length">
      <v-col>
        <h6>Languages:</h6>
      </v-col>
      <v-col>
        <h6
          class="text-caption"
          v-for="(item, i) in included_languages"
          :key="i"
        >
          {{ item ? item.language_name : null }}
        </h6>
      </v-col>
    </v-row>

    <v-row v-if="included_markets.length || excluded_markets.length">
      <v-col>
        <h6>Targeted Markets:</h6>
      </v-col>
      <v-col>
        <div v-if="included_markets.length" class="mb-3">
          <h6>Included Markets:</h6>
          <h6
            class="text-caption"
            v-for="(item, i) in included_markets"
            :key="i"
          >
            {{ item ? item.name : null }}
          </h6>
        </div>
        <div v-if="excluded_markets.length">
          <h6>Excluded Specific Markets:</h6>
          <h6
            class="text-caption"
            v-for="(item, i) in excluded_markets"
            :key="i"
          >
            {{ item ? item.name : null }}
          </h6>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    included_languages: [],
    included_markets: [],
    excluded_markets: [],
  }),
  props: {
    single_pretarget: {
      type: Object,
    },
    vertical_targeting: {
      type: Array,
    },
    languages: {
      type: Array,
    },
  },
  watch: {
    languages() {
      if (
        this.single_pretarget &&
        this.single_pretarget.includedLanguages &&
        this.single_pretarget.includedLanguages.length
      ) {
        let languages = this.single_pretarget.includedLanguages;
        languages.forEach((element) => {
          let allLanguages = this.languages.find(
            (language) => language.language_code === element
          );
          this.included_languages.push(allLanguages);
        });
      }
    },
    vertical_targeting() {
      if (
        this.single_pretarget &&
        this.single_pretarget.verticalTargeting &&
        this.single_pretarget.verticalTargeting.includedIds &&
        this.single_pretarget.verticalTargeting.includedIds.length
      ) {
        this.single_pretarget.verticalTargeting.includedIds
          .flat()
          .map(
            (id) =>
              (this.included_markets = this.vertical_targeting.filter(
                (market) => market.code === id
              ))
          );
      }

      if (
        this.single_pretarget &&
        this.single_pretarget.verticalTargeting &&
        this.single_pretarget.verticalTargeting.excludedIds &&
        this.single_pretarget.verticalTargeting.excludedIds.length
      ) {
        this.single_pretarget.verticalTargeting.excludedIds
          .flat()
          .map(
            (id) =>
              (this.excluded_markets = this.vertical_targeting.filter(
                (market) => market.code === id
              ))
          );
      }
    },
  },
};
</script>

<style></style>
