<template>
  <div v-if="included_locations.length || excluded_locations.length">
    <v-row>
      <v-col cols="3">
        <h6>GeoTargeting</h6>
      </v-col>
      <v-col v-if="single_pretarget.geoTargeting">
        <div v-if="single_pretarget.geoTargeting.includedIds">
          <h6>Included Locations</h6>
          <h6
            class="text-caption"
            v-for="(item, i) in included_locations"
            :key="i"
          >
            {{ item ? item.location_name : null }}
          </h6>
        </div>
        <div v-if="single_pretarget.geoTargeting.excludedIds" class="mt-3">
          <h6>Excluded Locations</h6>
          <h6
            class="text-caption"
            v-for="(item, i) in excluded_locations"
            :key="i"
          >
            {{ item ? item.location_name : null }}
          </h6>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="single_pretarget.invalidGeoIds">
      <v-col cols="3">
        <h6>Invalid Locations</h6>
      </v-col>
      <v-col>
        <h6>Invalid Locations from location targets</h6>
        <h6
          class="text-caption"
          v-for="(item, i) in single_pretarget.invalidGeoIds"
          :key="i"
        >
          {{ item }}
        </h6>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    included_locations: [],
    excluded_geo_objects: [],
    excluded_locations: [],
    included_geoids: [],
    excluded_geoids: [],
  }),
  props: {
    single_pretarget: {
      type: Object,
    },
    geo_locations_by_id: {
      type: Array,
    },
    included_geo_locations_by_id:{
      type: Array,
    },
    excluded_geo_locations_by_id:{
      type: Array,
    }
  },
  mounted() {
    if (
      this.single_pretarget &&
      this.single_pretarget.geoTargeting &&
      this.single_pretarget.geoTargeting.includedIds &&
      this.single_pretarget.geoTargeting.includedIds.length
    ) {
      this.included_geoids = Object.values(
        this.single_pretarget.geoTargeting.includedIds
      );
      this.$emit("setIncludedId", this.included_geoids);
    }

    if (
      this.single_pretarget &&
      this.single_pretarget.geoTargeting.excludedIds &&
      this.single_pretarget.geoTargeting.excludedIds.length
    ) {
      this.excluded_geoids = Object.values(
        this.single_pretarget.geoTargeting.excludedIds
      );
      this.$emit("setExcludedId", this.excluded_geoids);
    }
  },
  watch: {
    included_geo_locations_by_id(){
      if (this.included_geoids) {
        let locos = this.included_geoids;
        locos.forEach((element) => {
          let allLocations = this.included_geo_locations_by_id;
          let match = allLocations.find(
            (location) => location.geo_id === element
          );
          this.included_locations.push(match);
        });
      }
    },
    excluded_geo_locations_by_id(){
      if (this.excluded_geoids) {
        let excluded_locos = this.excluded_geoids;
        excluded_locos.forEach((element) => {
          let allLocations = this.excluded_geo_locations_by_id;
          let match = allLocations.find(
            (location) => location.geo_id === element
          );
          this.excluded_locations.push(match);
        });
      }
    }
  },
};
</script>

<style></style>
